import React, { useState, useEffect } from 'react';
import './App.css';
import Footer from './Components/Footer/Footer.jsx';
import Navbar from './Components/Navbar/Navbar';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Sicons from './Components/Slider/Icon.jsx';
import SevrvicesPage from './Pages/SevrvicesPage';
import OffersPage from './Pages/OffersPage';
import ServiceDetail from './Pages/ServiceDetail';
import BestSeller from './Pages/BestSellerpage';
import Admin from './Pages/AdminPanlePage.jsx';
import Login from './Pages/LoginPage/Login';
import Home from './Pages/Home';
import PrudactPage from './Pages/prudactPage/PrudactPage.jsx';
import CategoryPage from './Pages/CategoryPage/CategoryPage.jsx';
import AboutPage from './Pages/AboutPage/AboutPage.jsx';
import cookies from 'js-cookie';
import i18n from 'i18next';
import PrudactDetails from './Pages/PrudactDetails/PrudactDetails.jsx';
import NewarrivalPage from './Pages/newArrivalPage.jsx';
import SearchPage from './Pages/SearchPage.jsx';
import NotFoundPage from './Pages/NotFoundPage'; // Import the NotFoundPage component

function App() {


  function PrivateRoute({ element: Element, ...rest }) {
    const isLoggedIn = !!sessionStorage.getItem('token');
    return isLoggedIn ? <Element {...rest} /> : <Navigate to="/login" />;
  }

  const lng = cookies.get('i18next') || '';

  useEffect(() => {
    const contactElement3 = document.querySelector('#footer');
    if (contactElement3) {
      const direction = i18n.dir();
      contactElement3.dir = direction;
    }
  }, [lng]);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const LanguageHandler = () => {
    const query = useQuery();
    const lng = query.get('lng');

    useEffect(() => {
      if (lng) {
        i18n.changeLanguage(lng);
      }
    }, [lng]);

    return null;
  };
  if (typeof window === 'undefined') {
    return null; // or a loading state
  }
  return (
    <div className="main">
      <LanguageHandler />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<SevrvicesPage />} />
        <Route path="/offers" element={<OffersPage />} />
        <Route path="/category/:gen_category" element={<CategoryPage />} />
        <Route path="/services/:id/:gen_category/:Serv_name" element={<ServiceDetail />} />
        <Route path="/aboutus" element={<AboutPage />} />
        <Route path="/newarrival" element={<NewarrivalPage />} />
        <Route path="/product/:gen_category/:category_name/product/:id" element={<PrudactDetails />} />
        <Route path="/category/:gen_category/:category_name/products" element={<PrudactPage />} />
        <Route path="/product/search/:searchTerm" element={<SearchPage />} />
        <Route path="/bestseller" element={<BestSeller />} />
        <Route path="/adminpanal/*" element={<PrivateRoute element={Admin} />} />
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<NotFoundPage />} /> {/* Catch-all route */}
      </Routes>
    </div>
  );
}

function AppWrapper() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const location = useLocation();
  const [showNavbarAndSicons, setShowNavbarAndSicons] = useState(true);

  useEffect(() => {
    setShowNavbarAndSicons(!location.pathname.startsWith('/adminpanal'));
  }, [location.pathname]);

  return (
    <div>
      {showNavbarAndSicons && <Sicons />}
      {showNavbarAndSicons && <Navbar />}
      <App />
      {showNavbarAndSicons && <Footer />}
    </div>
  );
}

export default AppWrapper;



