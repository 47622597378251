import React, { useEffect, useState } from 'react';
import './Newarrival.css';
import ItemCard from '../ItemCard/Item';
import i18next from 'i18next';
import axios from 'axios';
import { PropagateLoader } from "react-spinners";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Newarrival = () => {
    const { t } = useTranslation();
    const [newProducts, setNewProducts] = useState([]);
    const [itemsToShow, setItemsToShow] = useState(5);
    const currentLanguage = i18next.language; // Get the current language from i18next.
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/general/all-products?lang=${currentLanguage}`;

    useEffect(() => {
        // Fetch data from the API
        axios.get(apiUrl)
            .then(response => {
                const products = response.data.products || response.data;

                if (Array.isArray(products)) {
                    // Filter products to include only those with isOffer set to true
                    const newProducts = products.filter(product => product.isNew);
                    // Update the state with the filtered data
                    setNewProducts(newProducts);

                    sessionStorage.setItem('newProducts', JSON.stringify(newProducts));
                }
            })
            .catch(error => {
                console.error('Error fetching the products:');
            });
    }, [apiUrl]); // Empty dependency array means this useEffect runs once after initial render

    const handleResize = () => {
        const width = window.innerWidth;
        if (width <= 940) {
            setItemsToShow(4); // Show 4 items at 930px or less
        } else {
            setItemsToShow(5); // Show 5 items for wider screens
        }
    };

    useEffect(() => {
        // Set initial number of items based on current width
        handleResize();
        // Add event listener for window resize
        window.addEventListener('resize', handleResize);
        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <section id='newarrival' className='newarrival' dir={i18next.dir()}>
            <div className='containerr-' style={{ minWidth: "40%" }}>
                <div className='newarrival-header'>
                    <div><h4> {t("newArriv")} </h4></div>
                    <div className='more-btn'>
                        <Link to="/newarrival"> {t("more-btn")}</Link>
                    </div>
                </div>

                <div className='pudact-container' id='prudactCards'>
                    {newProducts.length > 0 ? (
                        newProducts.slice(0, itemsToShow).map((item, i) => (
                            <ItemCard
                                key={i}
                                id={item.id}
                                name_ar={currentLanguage === 'en' ? item.name_en : item.name_ar}
                                img={item.imageProductCover}
                                imgs={item.productImages.map(image => (image))} // Ensure images are correctly formatted
                                brand_ar={currentLanguage === 'en' ? item.brand_en : item.brand_ar}
                                modle_ar={currentLanguage === 'en' ? item.model_en : item.model_ar}
                                description={currentLanguage === 'en' ? item.description_en : item.description_ar}
                                isOffer={item.isOffer}
                                rating={item.rating}
                                discount={item.discount}
                                isNew={item.isNew}
                            />
                        ))
                    ) : (
                        <div style={{ minWidth: "80%" }} >
                            <p><PropagateLoader color="#3A5998" /></p>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
}

export default Newarrival;
