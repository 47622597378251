// Pages/NotFoundPage.jsx
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
    const { t } = useTranslation()
    return (

        <div style={{ height: "100vh" }}>
            <Helmet>
                <title>404</title>
                <link rel="canonical" href="https://trendlineeg.com/" />
            </Helmet>
            <h1>404 - Not Found</h1>
            <p>The page you are looking for does not exist.</p>
            <Link to="/">{t("home")}</Link>
        </div>
    );
};

export default NotFoundPage;
