import React from 'react';
import ReactDOM from 'react-dom/client'; // Make sure React 18 is installed
import { ApiProvider } from './Context/ApiProvider.jsx';
import { DataProvider } from './Context/DataProvider.jsx';
import { HelmetProvider } from 'react-helmet-async';
import App from './App';
import './index.css';
import './i18n';

// Get the root element from the DOM
const rootElement = document.getElementById('root');

// Use createRoot for client-side rendering
const root = ReactDOM.createRoot(rootElement);

root.render(
  <HelmetProvider>
    <ApiProvider>
      <DataProvider>
        <App />
      </DataProvider>
    </ApiProvider>
  </HelmetProvider>
);
