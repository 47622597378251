import React, { useState, useEffect } from 'react';
import RelatedProducts from '../../Components/RelatedProduts/Related';
import StarsRating from '../../Components/StarsRating/StarsRating';
import './PrudactDetails.css';
import './ImageModal.css';
import { Link as LinkS } from 'react-scroll';
import { IoIosArrowDown } from "react-icons/io";
import { TbTruckDelivery } from "react-icons/tb";
import { GiStarsStack } from "react-icons/gi";
import { BsCashStack } from "react-icons/bs";
import { GridLoader } from "react-spinners";
import axios from 'axios';
import i18n from 'i18next';
import { HiMiniMagnifyingGlassPlus } from "react-icons/hi2";
import { GoDotFill } from "react-icons/go";
import { useParams } from 'react-router-dom';
import i18next from 'i18next';
import { t } from 'i18next';
import { IoHome } from "react-icons/io5";
import { Link } from 'react-router-dom';
// import { Helmet } from 'react-helmet-async';

const ProductDetails = () => {
    const { id } = useParams();
    const currentLanguage = i18next.language;
    const [product, setProduct] = useState(null);
    const [category, setCategory] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showMsg, setShowMsg] = useState(false);
    const [urName, setUrName] = useState('');
    const [urCity, setUrCity] = useState('');
    const [urPhone, setUrPhone] = useState('');
    const [urAddress, setUrAddress] = useState('');
    const [formValid, setFormValid] = useState(true);
    const [selectedImage, setSelectedImage] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [modalImageIndex, setModalImageIndex] = useState(0);

    const fetchProduct = async (retryCount = 5) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/general/all-products/product/${id}?lang=${currentLanguage}`);
            const fetchedProduct = response.data.product;
            const fetchedCategory = response.data.category;
            setProduct(fetchedProduct);
            setCategory(fetchedCategory);
            setSelectedImage(fetchedProduct.imageProductCover);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching product:', error.response ? error.response.data : error.message);
            if (retryCount === 0) {
                setError(error);
                setLoading(false);
            } else {
                setTimeout(() => fetchProduct(retryCount - 1), 2000); // Retry after 2 seconds
            }
        }
    };

    useEffect(() => {
        fetchProduct();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, currentLanguage]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleImageHover = (image) => {
        setSelectedImage(image);
    };

    const handleImageClick = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const handlePrev = () => {
        setModalImageIndex((prevIndex) => (prevIndex === 0 ? product.productImages.length - 1 : prevIndex - 1));
    };

    const handleNext = () => {
        setModalImageIndex((prevIndex) => (prevIndex === product.productImages.length - 1 ? 0 : prevIndex + 1));
    };

    if (loading) {
        return <div style={{ display: "flex", justifyContent: "center", alignItems: "center", position: "absolute", top: "0", left: "0", width: "100%", height: "100%", zIndex: "1500", backgroundColor: "#fff" }}>
            <GridLoader color="#3A5998" />
        </div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    if (!product || !category) {
        return <div>No product or category found</div>;
    };

    const handleMsgClick = () => {
        setShowMsg(!showMsg);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'name') setUrName(value);
        if (name === 'city') setUrCity(value);
        if (name === 'phone') setUrPhone(value);
        if (name === 'address') setUrAddress(value);
    };

    const constructWhatsAppLink = () => {
        const baseUrl = 'https://api.whatsapp.com/send';
        const phone = '201281717577'; // Replace with your WhatsApp number
        const message = `${t("ordermsg")} \n${t("Ordproduct")} ${currentLanguage === 'en' ? product.name_en : product.name_ar} \n${("Ordname")} ${urName}\n${t("Ordphone")} ${urPhone}\n${t("Ordcity")} ${urCity}\n${t("Ordaddress")} ${urAddress}`;
        return `${baseUrl}?phone=${phone}&text=${encodeURIComponent(message)}`;
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (urName && urPhone && urCity && urAddress) {
            // All fields are filled, allow submission
            window.open(constructWhatsAppLink(), '_blank', 'noreferrer');
        } else {
            // Show an error message or handle the case where not all fields are filled
            setFormValid(false);
        }
    };

    // const addCommas = (text) => {
    //     return text ? text.split(' ').join(', ') : '';
    // };

    const handleDescription = (text) => {
        if (!text) return '';

        // Extract text between double braces {{ }}
        const match = text.match(/\{\{(.*?)\}\}/s);
        let extractedText = match ? match[1].trim() : text;

        // Replace sequences of hyphens with non-breaking spaces
        const hyphenReplacedText = extractedText.replace(/---+/g, match => '&nbsp;'.repeat(match.length));

        // Regular expression to match text between asterisks
        const regex = /\*(.*?)\*/g;

        // Process text by replacing matched patterns with <strong> tags
        const processedText = hyphenReplacedText.replace(regex, (_, match) => (
            `<strong>${match}</strong>`
        ));

        // Split processed text by newlines and handle line breaks
        const parts = processedText.split('\n').map((line, lineIndex) => (
            <React.Fragment key={lineIndex}>
                <span style={{ position: 'relative' }}>
                    <GoDotFill style={{ fontSize: "0.6rem", margin: "0.5rem 0.1rem", padding: "0" }} />
                    <span dangerouslySetInnerHTML={{ __html: line }} />
                </span>
                <br />
            </React.Fragment>
        ));

        return parts;
    };

    const handleNoneDescription = (text) => {
        if (!text) return null;

        // Extract text between double braces {{ }}
        const match = text.match(/\{\{(.*?)\}\}/s);
        if (!match) return null; // Return null if no text between {{ }}

        const extractedText = match[1].trim();

        // Replace sequences of hyphens with non-breaking spaces
        const hyphenReplacedText = extractedText.replace(/-+/g, match => '&nbsp;'.repeat(match.length));

        // Regular expression to match text between asterisks
        const regex = /\*(.*?)\*/g;

        // Process text by replacing matched patterns with <strong> tags
        const processedText = hyphenReplacedText.replace(regex, (_, match) => (
            `<strong>${match}</strong>`
        ));

        // Split processed text by newlines and handle line breaks
        const parts = processedText.split('\n').map((line, lineIndex) => (
            <React.Fragment key={lineIndex}>
                <span style={{ position: 'relative' }}>
                    <GoDotFill style={{ fontSize: "0.6rem", margin: "0.5rem 0.1rem", padding: "0" }} />
                    <span dangerouslySetInnerHTML={{ __html: line }} />
                </span>
                <br />
            </React.Fragment>
        ));

        return parts.length ? parts : null; // Return null if there are no parts to display
    };

    const description = currentLanguage === 'en' ? product?.description_en : product?.description_ar;
    const descriptionContent = handleNoneDescription(description);

    const addBr = (text) => {
        if (!text) return '';

        // Remove text between double braces {{ }}
        const withoutBracesText = text.replace(/\{\{.*?\}\}/gs, '');

        // Replace sequences of hyphens with non-breaking spaces
        const hyphenReplacedText = withoutBracesText.replace(/---+/g, match => '&nbsp;'.repeat(match.length));

        // Regular expression to match text between asterisks
        const regex = /\*(.*?)\*/g;

        // Process text by replacing matched patterns with <strong> tags
        const processedText = hyphenReplacedText.replace(regex, (_, match) => (
            `<strong>${match}</strong>`
        ));

        // Split processed text by newlines and handle line breaks
        const parts = processedText.split('\n').map((line, lineIndex) => (
            <React.Fragment key={lineIndex}>
                <span style={{ position: 'relative' }}>
                    <GoDotFill style={{ fontSize: "0.6rem", margin: "0.5rem 0.1rem", padding: "0" }} />
                    <span dangerouslySetInnerHTML={{ __html: line }} />
                </span>
                <br />
            </React.Fragment>
        ));

        return parts;
    };

    const handleReload = (delay = 1000) => { // Default delay is 2000ms (2 seconds)
        setTimeout(() => {
            window.location.reload();
        }, delay);
    };


    return (
        <div>
            {/* <Helmet>
                <link rel="canonical" href={`https://trendlineeg.com/product/${category.category_en}/${category.name_en}/product/${id}`} />
                <title>{product.name_ar}</title>
                <meta name="description" content={product.description_en} />
                <meta name="keywords" content={addCommas(product.description_en)} />
                <meta property="og:title" content={product.name_en} />
                <meta property="og:description" content={product.description_en} />
                <meta property="og:image" content={product.imageProductCover} />
            </Helmet> */}
            <div className=' PpageH pageH container d-flex flex-worp' dir={i18next.dir()}>
                <Link to={"/"}><IoHome /></Link>
                &gt;
                <Link to={`/category/${currentLanguage === 'en' ? category.category_en : category.category_ar}`}> <p>{currentLanguage === 'en' ? category.category_en : category.category_ar}</p></Link>
                &gt;
                <Link to={`/category/${currentLanguage === 'en' ? category.category_en : category.category_ar}/${currentLanguage === 'en' ? category.name_en : category.name_ar}/products`}> <p>{currentLanguage === 'en' ? category.name_en : category.name_ar}</p></Link>
                &gt;
                <p className='PHname'>{currentLanguage === 'en' ? product.name_en : product.name_ar}</p>
            </div>
            <div className="ProductD-Page">
                <div className='productD' dir={i18n.dir()}>
                    <div className='imgshowcase'>
                        <div className='Pimgs'>
                            {product.productImages.map((image, index) => (
                                <div key={index} onMouseEnter={() => handleImageHover(image)}>
                                    <img
                                        src={`${image}`}
                                        alt={product.name_ar}
                                    />
                                </div>
                            ))}
                        </div>
                        <div className='Pimg-cover' onClick={handleImageClick}>
                            <div className='glassIcon'>
                                <i><HiMiniMagnifyingGlassPlus /></i>
                            </div>
                            <div className='product-labol' dir='ltr'>
                                {product.isOffer && product.discount > 0 ? <p className='product-labol-discount'>-{product.discount}%</p> : null}
                                {product.isNew ? <p className='product-labol-new'> {t("new")} </p> : null}
                            </div>
                            <img src={`${selectedImage}`} alt={product.name_ar} />
                        </div>
                    </div>
                    <div className='productDinfo'>
                        <p> <strong> {currentLanguage === 'en' ? product.name_en : product.name_ar} </strong> </p>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div style={{ display: "flex", alignItems: "center" }}> <h6 style={{ margin: " 0" }}> {t("model")} {currentLanguage === 'en' ? product.model_en : product.model_ar}</h6></div>
                            <div style={{ display: "flex", alignItems: "center", paddingTop: "1rem" }}> <StarsRating rating={product.rating} /></div>
                        </div>
                        <br />
                        <LinkS to={"desTable"} style={{ cursor: "pointer" }}> <h5> {t("Pdesc")} <IoIosArrowDown /> </h5></LinkS>
                        <p className='Pdesc-2' style={{ position: "relative" }}>
                            {handleDescription(currentLanguage === 'en' ? product?.description_en : product?.description_ar)}
                        </p>
                        <LinkS to={"desTable"} style={{
                            cursor: "pointer",
                            margin: "0",
                            padding: "0",
                            fontSize: "80%",
                            color: "#3A5998",
                            fontWeight: "bold"
                        }}><p>{t("more-btn")}</p></LinkS>
                        <h6> {t("brand")} {currentLanguage === 'en' ? product.brand_en : product.brand_ar}</h6>
                        <div className='values'>
                            <div className='value'>
                                <i> <TbTruckDelivery /> </i>
                                <p> {t("PDV1")} </p>
                            </div>
                            <div className='value'>
                                <i> <GiStarsStack /> </i>
                                <p>{t("PDV2")}</p>
                            </div>
                            <div className='value'>
                                <i> <BsCashStack /> </i>
                                <p>{t("PDV3")}</p>
                            </div>
                        </div>
                        <div className='order-nowP'>
                            <div onClick={handleMsgClick} style={{ color: "#fff" }} >{t("ordernow")}</div>
                        </div>
                    </div>
                </div>
                {showModal && (
                    <div className="modal-overlay" onClick={closeModal}>
                        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                            <div className='model-head'>
                                <div>
                                    <h5> {t("gallery")} </h5>
                                </div>
                                <div>
                                    <span className="close-btn" onClick={closeModal}>&times;</span>
                                </div>
                            </div>
                            <img src={`${product.productImages[modalImageIndex]}`} alt="Product" />
                            <div className="carousel-arrows">
                                <div>
                                    <button className="prev-btn" onClick={handlePrev}>&#10094;</button>
                                </div>
                                <div>
                                    <button className="next-btn" onClick={handleNext}>&#10095;</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {showMsg ? (
                    <div className='popup' style={{ minHeight: "70vh" }} onClick={handleMsgClick}>
                        <div className="popup-content" style={{ minHeight: "50vh", minWidth: "30vw", backgroundColor: "#DFE3EE" }} onClick={(e) => e.stopPropagation()}>
                            <div className="title-section">
                                <button className='close-btn' onClick={handleMsgClick}>&times;</button>
                                <p className="title">{t("ordernow")}</p>
                            </div>
                            <div className='order-content'>
                                <div className="popup-item">
                                    <form id="orderForm" className='msgs-info' onSubmit={handleFormSubmit}>
                                        <div className='msgs-info-row'>
                                            <div className='msg-info'>
                                                <p>{t("Contname")}</p>
                                                <input type="text" style={{ width: "98%" }} placeholder={t("Contname")} name='name' value={urName} onChange={handleInputChange} required />
                                            </div>
                                            <div className='msg-info'>
                                                <p>{t("Contphone")}</p>
                                                <input type="tel" style={{ width: "98%" }} placeholder={t("Contphone")} name='phone' value={urPhone} onChange={handleInputChange} required />
                                            </div>
                                        </div>
                                        <div className='msg-info'>
                                            <p>{t("Contcity")}</p>
                                            <input type="text" name='city' placeholder={t("Contcity")} value={urCity} onChange={handleInputChange} required />
                                        </div>
                                        <div className='msg-info'>
                                            <p>{t("ContAddress")}</p>
                                            <input type="text" name='address' placeholder={t("ContAddress")} value={urAddress} onChange={handleInputChange} required />
                                        </div>
                                        <button type="submit" className='popup-btn' style={{ color: "#fff", width: "100%", marginTop: "1.5rem", border: "none" }}>
                                            {t("ordernow")}
                                        </button>
                                    </form>
                                    {!formValid && <p style={{ color: 'red', marginTop: '1rem' }}>Please fill out all fields before submitting.</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}


            </div>
            <div id='desTable' style={{ display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "column" }} dir={i18next.dir()} >
                <br />
                <br />
                <br />
                <div>
                    <h5 style={{ width: "100%", textAlign: "center" }}>{t("Pdesc")}</h5>
                </div>
                <div style={{ display: "flex", justifyContent: "center", alignContent: "center", width: "100%" }}>
                    <p className='Pdesc' style={{ position: "relative", width: "80%", background: "#DFE3EE40", padding: "1.5rem 0.5rem", borderRadius: "20px" }}>{addBr(currentLanguage === 'en' ? product?.description_en : product?.description_ar)}</p>
                </div>
                {descriptionContent && (
                    <div style={{ display: "flex", flexDirection: "column", margin: "0 10%", alignContent: "center", width: "100%" }}>
                        <h5 style={{ width: "80%" }}>{t("PdescIfo")}</h5>
                        <p className='Pdesc-3' style={{ position: "relative", width: "45%", background: "#DFE3EE40", padding: "1.5rem 0.5rem", borderRadius: "20px" }}>
                            {descriptionContent}
                        </p>
                    </div>
                )}


            </div>
            <div style={{ margin: "1rem 0" }}>
                <RelatedProducts onClick={handleReload} Serv_name={currentLanguage === 'en' ? category.name_en : category.name_ar} gen_category={currentLanguage === 'en' ? category.category_en : category.category_ar} />
            </div>
        </div >
    );
}

export default ProductDetails;
